import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const invitation = params.get("invitation");
    const organization = params.get("organization");

    loginWithRedirect({
      authorizationParams: {
        audience: "https://dev.datasteward.ai",
        organization: organization ?? undefined,
        invitation: invitation ?? undefined,
        screen_hint: invitation ? "signup" : undefined,
      },
    });
  }, [loginWithRedirect]);

  return <></>;
}
