import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from "jwt-decode";
import { useCookie } from "react-use";
import { z } from "zod";

import { env } from "@/env";
import { getCookie } from "@/lib/cookie";

import { getGraphqlClient } from "../graphql";

const JWT_COOKIE_NAME = "auth0-jwt";

const useJwtCookie = () => {
  return useCookie(JWT_COOKIE_NAME);
};

const setAuthorizationHeader = (jwt: string | null) => {
  const graphqlClient = getGraphqlClient();
  graphqlClient.setHeader("authorization", `Bearer ${jwt}`);
};

export const useSetJwtCookieAndBearer = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const [jwtCookie, setJwtCookie, deleteJwtCookie] = useJwtCookie();

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: env.VITE_PUBLIC_AUTH0_AUDIENCE,
        },
      })
        .then((accessToken) => {
          setJwtCookie(accessToken);
        })
        .catch((error) => {
          deleteJwtCookie();
          console.error("Error obtaining access token:", error);
        });
    }
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  useEffect(() => {
    setAuthorizationHeader(jwtCookie);
  }, [jwtCookie]);

  return { jwtCookie };
};

export const claimsKey = `${env.VITE_PUBLIC_AUTH0_AUDIENCE}/claims`;

const claimsSchema = z.object({
  email: z.string(),
  id: z.number(),
  orgId: z.number(),
});

const decodedJwtSchema = z.object({
  [claimsKey]: claimsSchema,
  iss: z.string(),
  sub: z.string(),
  aud: z.array(z.string()),
  iat: z.number(),
  exp: z.number(),
  azp: z.string(),
  scope: z.string(),
  org_id: z.string(),
  permissions: z.array(z.string()),
});

type DecodedJwt = z.infer<typeof decodedJwtSchema>;
type DecodedJwtWithClaims = DecodedJwt & {
  claims: z.infer<typeof claimsSchema>;
};

export const useDecodedJwtCookie = () => {
  const [jwt] = useJwtCookie();
  const [decodedJwt, setDecodedJwt] = useState<DecodedJwtWithClaims | null>(
    jwt ? getDecodedJwtCookie() : null,
  );

  useEffect(() => {
    if (jwt) {
      try {
        const _decodedJwt = decodedJwtSchema.parse(jwtDecode(jwt));
        const claims = claimsSchema.parse(_decodedJwt[claimsKey]);

        // @ts-ignore - TODO: Ben looking into this
        setDecodedJwt({ ..._decodedJwt, claims });
      } catch (error) {
        console.error("Error decoding JWT:", error);
      }
    }
  }, [jwt]);

  return { decodedJwt };
};

// non-hook versions for route loader
const getJwtCookie = () => {
  return getCookie(JWT_COOKIE_NAME);
};
export const getDecodedJwtCookie = (): DecodedJwtWithClaims | null => {
  const jwt = getJwtCookie();
  if (!jwt) return null;

  try {
    const decodedJwt = decodedJwtSchema.parse(jwtDecode(jwt));
    const claims = claimsSchema.parse(decodedJwt[claimsKey]);
    // @ts-ignore - TODO: Michael looking into this
    return { ...decodedJwt, claims };
  } catch (error) {
    console.error("Error decoding JWT:", error);
    return null;
  }
};
