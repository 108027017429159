import { Suspense } from "react";
import {
  createBrowserRouter,
  createSearchParams,
  Link,
  Outlet,
  redirect,
  resolvePath,
  RouteObject,
} from "react-router-dom";
import { match, P } from "ts-pattern";
import { z } from "zod";

import { DashboardLayout } from "@/app/dashboard/DashboardLayout";
import ErrorPage from "@/app/ErrorPage";
import App from "@/app/layout";
import { VendorUploadPage } from "@/app/vendor-submit/:vendorId/home/VendorUploadPage";
import { LoadingPage } from "@/app/vendor-submit/:vendorId/submission/:submissionId/LoadingPage";
import { VendorSubmissionLayout } from "@/app/vendor-submit/:vendorId/submission/:submissionId/VendorSubmissionLayout";

import { AuthenticationGuard } from "./app/AuthenticationGuard";
import { dashboardRoutes } from "./app/dashboard/dashboardRoutes";
import { Help } from "./app/help/page";
import { LoginPage } from "./app/login/LoginPage";
import { Logout } from "./app/logout/page";
import { ConfirmMappingPageWrapper } from "./app/vendor-submit/:vendorId/confirm-mapping/ConfirmMappingPageWrapper";
import { getSubmissionRouteQueryOptions } from "./app/vendor-submit/:vendorId/submission/:submissionId/getSubmissionRouteQueryOptions";
import { getSubmissionNormalMapQueryOptions } from "./app/vendor-submit/:vendorId/submission/:submissionId/normalize/getSubmissionNormalMapQueryOptions";
import { NormalizeMappingPageWrapper } from "./app/vendor-submit/:vendorId/submission/:submissionId/normalize/NormalizeMappingPageWrapper";
import { EnrichmentPage } from "./app/vendor-submit/:vendorId/submission/:submissionId/review/EnrichmentPage";
import { ValidationPage } from "./app/vendor-submit/:vendorId/submission/:submissionId/review/validation/ValidationPage";
import { YourExtractionsPageWrapper } from "./app/vendor-submit/:vendorId/submission/:submissionId/review/YourExtractionsPageWrapper";
import { SummaryPage } from "./app/vendor-submit/:vendorId/submission/:submissionId/summary/SummaryPage";
import { buttonVariants } from "./components/ui/button";
import {
  Submission,
  SubmissionStatus,
  TransformationStatus,
  Vendor,
} from "./gql/graphql";
import { ensureQueryData } from "./lib/hooks/graphql";
import { getDecodedJwtCookie } from "./lib/hooks/queries/Auth0";
import { getVendorSubmissionTypesQueryOptions } from "./lib/hooks/queries/SubmissionTypes";
import { getUserQueryOptions } from "./lib/hooks/queries/User";
import { documentExtractRoutes } from "./app/document-extract/documentExtractRoutes";

export enum SubmissionRoutes {
  Normalize = "normalize",
  Review = "review",
  Summary = "summary",
  Loading = "loading",
}

export enum ReviewRoutes {
  Validation = "validation",
}

const NORMAL_DIALOG_QUERY_PARAM = "normalize_dialog";
const NORMAL_CONFIG_CREATE = "create";
const NORMAL_CONFIG_UPDATE = "update";

export const routes = [
  {
    path: "/",
    Component: App,
    ErrorBoundary: ErrorPage,
    children: [
      {
        id: "Old Splash Route",
        path: "",
        loader: () => {
          return redirect("/dashboard");
        },
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        ...documentExtractRoutes,
      },
      {
        id: "Dashboard",
        path: "dashboard",
        element: <AuthenticationGuard component={DashboardLayout} />,
        children: dashboardRoutes,
        loader: () => {
          const decodedJwt = getDecodedJwtCookie();
          if (decodedJwt === null) return null;

          const options = getUserQueryOptions({ id: decodedJwt?.claims.id });
          // ensure user is being fetched prior to rendering dashboard
          return ensureQueryData(options);
        },
      },
      {
        path: "vendor-submit",
        loader: async ({ params }) => {
          const getOKTAJwt = async () => "fake-jwt";
          const getVendorIdFromOKTAJwt = async (): Promise<Vendor["id"]> => 1;
          const vendorId = params?.vendorId;

          const oktaJwt = await getOKTAJwt();

          // if no OKTA JWT, redirect to... somewhere...
          if (!oktaJwt) return redirect("/vendor-auth-messed-up");

          // if no vendorId in params, find it via the OKTA JWT/our db and redirect to the correct vendor
          if (!vendorId) {
            const vendorIdFromOKTA = await getVendorIdFromOKTAJwt();
            return redirect(`/vendor-submit/${vendorIdFromOKTA}`);
          }

          return null;
        },
        element: <Outlet />,
        children: [
          {
            path: ":vendorId", // putting vendorId in the path allows org members to access a vendor's submit page
            element: <Outlet />,
            children: [
              {
                path: "",
                loader: async ({ params, request }) => {
                  let vendorId: Vendor["id"] = Number(params.vendorId);

                  try {
                    vendorId = z.number().parse(vendorId); // catches NaN
                  } catch (e) {
                    if (e instanceof z.ZodError)
                      console.error("vendorId is not valid", e.errors);
                    return redirect("/vendor-auth-messed-up");
                  }

                  ensureQueryData(
                    getVendorSubmissionTypesQueryOptions(vendorId),
                  );

                  return null;
                },
                element: (
                  <Suspense>
                    <VendorUploadPage />
                  </Suspense>
                ),
              },
              {
                path: "confirm-mapping",
                children: [
                  {
                    path: ":submissionTypeId",
                    element: <ConfirmMappingPageWrapper />,
                  },
                ],
                // element: <NormalizeMappingPageWrapper />,
              },
              {
                path: "submission",
                element: <VendorSubmissionLayout />,
                loader: async ({ params }) => {
                  const vendorId: Vendor["id"] = Number(params.vendorId);

                  ensureQueryData(
                    getVendorSubmissionTypesQueryOptions(vendorId),
                  );

                  if (!params.submissionId) {
                    return redirect(`/vendor-submit/${params.vendorId}`);
                  }
                  return null;
                },
                children: [
                  {
                    path: ":submissionId",
                    element: <Outlet />,
                    // responsible for conditional routing of a particular submission
                    loader: async ({ params, request }) => {
                      const vendorId: Vendor["id"] = Number(params.vendorId);
                      let submissionId: Submission["id"] = Number(
                        params.submissionId,
                      );

                      try {
                        submissionId = z.number().parse(submissionId);
                      } catch (e) {
                        if (e instanceof z.ZodError)
                          console.error("submissionId is not valid", e.errors);
                        return redirect(`/vendor-submit/${params.vendorId}`);
                      }

                      ensureQueryData(
                        getSubmissionNormalMapQueryOptions({
                          submissionId,
                          vendorId,
                        }),
                      );

                      const data = await ensureQueryData(
                        getSubmissionRouteQueryOptions({
                          submissionId,
                          vendorId,
                        }),
                      );

                      const submission = data?.submission;

                      const requestPath = new URL(request.url).pathname;
                      const baseSubmissionPath = `/vendor-submit/${params.vendorId}/submission/${submissionId}`;

                      return (
                        match(submission?.status)
                          .with(undefined, () => {
                            return redirect(
                              `/vendor-submit/${params.vendorId}`,
                            );
                          })
                          .with(
                            P.union(SubmissionStatus.AwaitingPreprocessing),
                            () => {
                              const hasMapping = false;
                              const hasCorrectMapping = hasMapping && false;

                              if (!hasMapping || !hasCorrectMapping) {
                                const queryParams = createSearchParams({
                                  [NORMAL_DIALOG_QUERY_PARAM]: hasMapping
                                    ? NORMAL_CONFIG_UPDATE
                                    : NORMAL_CONFIG_CREATE,
                                });

                                const { pathname } = resolvePath(
                                  `${SubmissionRoutes.Normalize}?${queryParams}`,
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              } else {
                                const { pathname } = resolvePath(
                                  SubmissionRoutes.Review,
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              }
                            },
                          )
                          .with(
                            P.union(
                              SubmissionStatus.Pending,
                              SubmissionStatus.Processing,
                            ),
                            () => {
                              const { pathname } = resolvePath(
                                SubmissionRoutes.Loading,
                                baseSubmissionPath,
                              );

                              if (requestPath !== pathname)
                                return redirect(pathname);
                            },
                          )
                          .with(
                            P.union(
                              SubmissionStatus.Failed,
                              SubmissionStatus.Processed,
                              SubmissionStatus.VendorReview,
                            ),
                            () => {
                              const firstNotApprovedTransformation =
                                submission?.transformations?.find(
                                  (transformation) =>
                                    transformation.status !==
                                    TransformationStatus.Approved,
                                );

                              if (firstNotApprovedTransformation != null) {
                                const { pathname } = resolvePath(
                                  SubmissionRoutes.Review,
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              } else {
                                const { pathname } = resolvePath(
                                  SubmissionRoutes.Summary,
                                  baseSubmissionPath,
                                );

                                if (requestPath !== pathname)
                                  return redirect(pathname);
                              }
                            },
                          )
                          .with(
                            P.union(
                              SubmissionStatus.VendorApproved,
                              SubmissionStatus.VendorRejected,

                              SubmissionStatus.OrgReview,
                              SubmissionStatus.OrgApproved,
                              SubmissionStatus.OrgRejected,

                              SubmissionStatus.ReadyForDelivery,
                              SubmissionStatus.Delivered,
                            ),
                            () => {
                              const { pathname } = resolvePath(
                                SubmissionRoutes.Summary,
                                baseSubmissionPath,
                              );

                              if (requestPath !== pathname)
                                return redirect(pathname);
                            },
                          )
                          .exhaustive() ?? null
                      );
                    },
                    children: [
                      {
                        path: SubmissionRoutes.Normalize,
                        loader: async ({ params }) => {
                          const vendorId = Number(params.vendorId);
                          const submissionId = Number(params.submissionId);

                          const data = await ensureQueryData(
                            getSubmissionRouteQueryOptions({
                              submissionId,
                              vendorId,
                            }),
                          );

                          const fileId = data?.submission?.fileId;
                          const submissionTypeId = data?.submission?.type?.id;

                          if (!fileId) {
                            console.error("No file ID found for submission");
                            return redirect(
                              `/vendor-submit/${params.vendorId}`,
                            );
                          }
                          if (!submissionTypeId) {
                            console.error(
                              "No submission type ID found for submission",
                            );
                            return redirect(
                              `/vendor-submit/${params.vendorId}`,
                            );
                          }

                          return { fileId, submissionTypeId };
                        },
                        element: <NormalizeMappingPageWrapper />,
                      },
                      {
                        path: SubmissionRoutes.Loading,
                        element: <LoadingPage />,
                      },
                      {
                        path: SubmissionRoutes.Review,
                        children: [
                          {
                            path: "",
                            element: <YourExtractionsPageWrapper />,
                          },
                          {
                            path: ReviewRoutes.Validation,
                            element: <ValidationPage />,
                          },
                          {
                            path: ":transformationId",
                            element: (
                              <Suspense>
                                <EnrichmentPage />
                              </Suspense>
                            ),
                          },
                        ],
                      },
                      {
                        path: SubmissionRoutes.Summary,
                        element: (
                          <Suspense>
                            <SummaryPage />
                          </Suspense>
                        ),
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "vendor-auth-messed-up",
        element: (
          <div className="flex flex-col gap-4">
            The "Something went wrong with vendor Auth" Screen
            <div>
              <Link
                to="/vendor-submit"
                className={buttonVariants({ variant: "default" })}
              >
                Vendor Submit
              </Link>
            </div>
          </div>
        ),
      },
    ],
  },
] satisfies RouteObject[];

export const router = createBrowserRouter(routes);
